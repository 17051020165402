
import "./TabStyles.css"
function QA() {
    return (
        <div className="wrapperQA">
        <iframe title="QA" src="https://wall.sli.do/event/1g603b3q?section=8002f715-7dcd-4997-b790-087483403dc7" frameborder="0" width="1440" height="839" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
        </div>
    );
  }
  
  export default QA;
  